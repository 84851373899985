.button {
	align-items: baseline;
	appearance: auto;
	color: var(--color-link);
	display: inline-flex;
	font-weight: 600;
	gap: 2px;
	max-height: 1em;
}

.text {
	text-decoration: underline;
	text-underline-offset: 2px;

	.button:hover & {
		text-decoration: none;
	}
}

.icon {
	align-self: flex-end;
	transform: translateY(2px);
}
